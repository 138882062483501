
/* eslint-disable */
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import jQuery from "jquery";
const customColorMethod = (percentage: number) => {
  if (percentage < 60) {
    return "#f56c6c";
  }
  return "#67c23a";
};
const dialogVisible = ref(false);
const $ = jQuery;
const video = ref();
const canvas = ref();
const captures = ref([] as Array<object>);
const isCameraOpen = ref(false);
const listData = ref();
const page = ref(0);
const score = ref(0);
const answer = ref([] as Array<object>);
const answersList = ref([
  {
    questionId: "",
    choiceId: [],
    answerUser: "",
  },
]);
import router from "@/router";
const submitButton =ref(false)
export default defineComponent({
  name: "blog-detail",
  components: {},
  data() {
    return {
      checkPermission: 0,
      timeInSecs: 0,
      ticker: 0,
      imageUrl: process.env.VUE_APP_IMG_URL,
      countdown: "",
      dataParams: this.$route.params,
      localStream: {
        srcObject: "",
      },
      componentKey: 0,
      percentPass: 60,
      currentScore: 0,
      currentScoreUser: 0,
      currentPercent: 0,
      currentRound: 0,
      chapterName: "",
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      // if (localStorage.getItem("answer")) {
      //   answer.value = JSON.parse(localStorage.getItem("answer")!);
      // }
      answersList.value = [];
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExamQuestion?examId=" +
            route.params.exId,
          {
            headers: {
               token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          listData.value = response.data.data;
          // console.log(listData.value);
          setCurrentPageBreadcrumbs(listData.value.title, ["หลักสูตรทั่วไป"]);
        })
        .catch((error) => {
          // console.log(error);
        });
      if (isCameraOpen.value == false) {
        // console.log(video.value);
      }
    };
    const register = () => {
      router.push({ name: "voluntary-learn", params: { id: route.params.id } });
    };
    const previousPage = () => {
      page.value = page.value - 1;
    };
    const nextPage = () => {
      page.value = page.value + 1;
    };
    const changePage = (event) => {
      page.value = event;
    };
    const setAnswer = (qId, dataAnswer, type, page) => {
      const list: any = {};
      if (answersList.value.length > 0) {
        // console.log(answersList.value);
        if (
          setDataInArrayObjectById(answersList.value, qId, dataAnswer, type) ==
          false
        ) {
          if (type == 1) {
            answer.value[page] = dataAnswer;
            list.questionId = qId;
            list.choiceId = [dataAnswer];
            list.answerUser = "";
          } else {
            list.questionId = qId;
            list.choiceId = [];
            list.answerUser = dataAnswer;
          }
        } else {
          for (const loopAnswer of answersList.value) {
            let data: any = [];
            data.push(dataAnswer);
            // console.log(dataAnswer);
            if (loopAnswer.questionId == qId) {
              if (type == 1) {
                answer.value[page] = dataAnswer;
                loopAnswer.choiceId = data;
                loopAnswer.answerUser = "";
              } else {
                loopAnswer.choiceId = [];
                loopAnswer.answerUser = dataAnswer;
              }
            }
          }
        }
      } else {
        if (type == 1) {
          answer.value[page] = dataAnswer;
          list.questionId = qId;
          list.choiceId = [dataAnswer];
          list.answerUser = "";
        } else {
          list.questionId = qId;
          list.choiceId = [];
          list.answerUser = dataAnswer;
        }
      }
      if (list.questionId) {
        answersList.value.push(list);
      }

      // localStorage.setItem("qId" + qId, qaId);
    };
    const setDataInArrayObjectById = (array, id, answer, type) => {
      var foundObject = array.find((obj) => obj.questionId === id);
      if (!foundObject) {
        return false;
      }
    };
    return {
      getDataList,
      listData,
      register,
      page,
      previousPage,
      nextPage,
      answer,
      changePage,
      setAnswer,
      video,
      canvas,
      captures,
      isCameraOpen,
      route,
      answersList,
      setDataInArrayObjectById,
      dialogVisible,
      customColorMethod,
      submitButton
    };
  },
  async mounted() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
      this.componentKey += 1;
      page.value = 0;
    }
    let exam = await axios.get(
      process.env.VUE_APP_API_URL + "/getExam?id=" + this.$route.params.exId,
      {
        headers: {
           token: localStorage.getItem("id_token")
        },
      }
    );

    this.percentPass = exam.data.data.content[0].percentPass;
    let courseSection = await axios.get(
      process.env.VUE_APP_API_URL +
        "/getCourseSection?id=" +
        this.$route.params.secId,
      {
        headers: {
           token: localStorage.getItem("id_token")
        },
      }
    );
    for (const loopChapter of courseSection.data.data.content[0].chapters) {
      if (loopChapter.chapterId == this.$route.params.chId) {
        this.chapterName = loopChapter.titleChapter;
      }
    }

    // this.createCameraElement();
    this.startTimer(exam.data.data.content[0].limitTime * 60);
  },
  methods: {
    todo() {
      isCameraOpen.value = true;
      this.createCameraElement();
      window.setInterval(() => {
        this.capture();
      }, 2000);
    },
    checkCamera() {
      this.checkPermission = window.setInterval(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then((stream) => {
              // console.log(stream);
            })
            .catch((error) => {
              clearInterval(this.checkPermission);
              // console.log(error);
              Swal.fire({
                title: "กรุณาให้สิทธิ webcam ในการทำข้อสอบ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#323563",
                cancelButtonColor: "#d33",
                confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
                allowOutsideClick: false,
                cancelButtonText: "กลับสู่หน้าหลัก",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.createCameraElement();
                } else {
                  router.push({
                    name: "voluntary-learn",
                    params: {
                      id: this.$route.params.cId,
                    },
                  });
                }
              });
            });
        }
      }, 2000);
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    toggleCamera() {
      if (isCameraOpen.value) {
        isCameraOpen.value = false;
        this.stopCameraStream();
      } else {
        isCameraOpen.value = true;
        this.createCameraElement();
      }
      //  createCameraElement();
      //  stopCameraStream();
    },
    createCameraElement() {
      localStorage.setItem("video", "true");
      isCameraOpen.value = true;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            video.value.srcObject = stream;
            video.value.play();
            this.startTimer(5 * 60);
            this.checkCamera();
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "กรุณาให้สิทธิ webcam ในการทำข้อสอบ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#323563",
              cancelButtonColor: "#d33",
              confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
              allowOutsideClick: false,
              cancelButtonText: "กลับสู่หน้าหลัก",
            }).then((result) => {
              if (result.isConfirmed) {
                this.createCameraElement();
              } else {
                router.push({
                  name: "voluntary-learn",
                  params: {
                    id: this.$route.params.cId,
                  },
                });
              }
            });
          });
      }
    },
    stopCameraStream() {
      video.value = video.value.srcObject.getTracks();
      video.value.forEach((track) => {
        track.stop();
      });
    },
    capture() {
      /* eslint-disable */
      canvas.value = this.$refs.canvas;
      var context: any = canvas.value
        .getContext("2d")
        .drawImage(this.video, 0, 0, 640, 480);
      // console.log(canvas.value.toDataURL("image/png"));
      captures.value.push(canvas.value.toDataURL("image/png"));
      // this.captures.push(canvas.value.toDataURL("image/png"));
      /* eslint-disable */
    },
    startTimer(secs) {
      this.timeInSecs = parseInt(secs);
      this.ticker = window.setInterval(() => {
        this.tick();
      }, 1000);
    },
    tick() {
      var secs = this.timeInSecs;
      if (secs > 0) {
        this.timeInSecs--;
      } else {
        clearInterval(this.ticker);
        Swal.fire({
          title: "หมดเวลาทำข้อสอบแล้ว!",
          text: "ต้องการส่งคำตอบหรือไม่ ?",
          icon: "error",
          buttonsStyling: false,
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: "ส่งข้อสอบ",
          cancelButtonText: "ยกเลิก",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-primary",
            cancelButton: "btn fw-semobold btn-light-danger",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.submit();
          } else {
            this.$router.go(-1);
          }
        });
      }
      var mins = Math.floor(secs / 60);
      secs %= 60;
      var pretty =
        (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "") + secs;
      this.countdown = pretty;
      localStorage.setItem("timer", secs + "");
    },
    percentageToDegrees(percentage) {
      return (percentage / 100) * 360;
    },
    async submit() {
      submitButton.value=true
      Swal.fire({
        title: "ต้องการส่งคำตอบใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#323563",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        allowOutsideClick: false,
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          clearInterval(this.ticker);
          clearInterval(this.checkPermission);
          let examId = this.$route.params.exId;
          let courseRegisterId = await this.getCourseRegister(
            this.$route.params.cId
          );
          // console.log(courseRegisterId);

          score.value = 0;
          axios
            .post(
              process.env.VUE_APP_API_URL + "/checkAnswer",
              answersList.value,
              {
                headers: {
                   token: localStorage.getItem("id_token")
                },
              }
            )
            .then((res) => {
              score.value = res.data.data.score;
              const formData = new FormData();
              formData.append("score", score.value + "");
              formData.append("examId", examId + "");
              formData.append("courseRegisterId", courseRegisterId + "");
              formData.append("flgPretest", this.$route.params.type == 'pretest' ? 'true' :'false');
              axios
                .post(process.env.VUE_APP_API_URL + "/updateScore", formData, {
                  headers: {
                     token: localStorage.getItem("id_token")
                  },
                })
                .then(async (res) => {
                  Swal.close();
                  let percent = await this.formatNumber(
                    (score.value / listData.value.length) * 100
                  );
                  this.currentScore = listData.value.length;
                  this.currentScoreUser = score.value;
                  this.currentPercent = percent;
                  this.currentRound = res.data.data.round;
                  console.log(percent);
                  
                  if (percent >= this.percentPass) {
                    dialogVisible.value = true;
                    answer.value = [];
                    await this.updateProgress();
                  } else {
                    dialogVisible.value = true;
                    answer.value = [];
                    console.log(this.$route.params.type);
                    if (this.$route.params.type == "pretest") {
                      await this.updateProgress();
                    }
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    title: "แจ้งเตือนข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-danger",
                    },
                  });
                });
            })
            .catch((error) => {
              // console.log(error);
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: error.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      });
    },
    async getCourseRegister(courseId) {
      let courseRegisterId = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getCourseRegister?courseId=" +
          courseId +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: {
             token: localStorage.getItem("id_token")
          },
        }
      );
      // console.log(courseRegisterId.data.data);
      return courseRegisterId.data.data.content[0].id;
    },
    async updateProgress() {
      const formData = new FormData();
      formData.append("courseId", this.$route.params.cId + "");
      formData.append("sectionId", this.$route.params.secId + "");
      formData.append("chapterId", this.$route.params.chId + "");
      // console.log("update");
      await axios
        .post(process.env.VUE_APP_API_URL + "/updateProgress", formData, {
          headers: {
             token: localStorage.getItem("id_token")
          },
        })
        .then((res) => {
          // console.log(res);
          localStorage.setItem("reloaded", "1");
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            title: "แจ้งเตือนข้อผิดพลาด",
            text: error.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    },
    formatNumber(score) {
      return parseInt(score);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    onBack() {
      submitButton.value=false
      dialogVisible.value = false;
      router.go(-1);
    },
  },
  beforeUnmount() {
    localStorage.setItem("reloaded", "1");
    clearInterval(this.ticker);
  },
});
